<template>
   <div
      @click="focusNewTag()"
      :class="{
         'vue-input-tag-wrapper--active': isInputActive,
      }"
      class="vue-input-tag-wrapper"
   >
      <span v-for="(tag, index) in innerTags" :key="index" class="input-tag">
         <span>{{ tag }}</span>
         <a @click.prevent.stop="remove(index)" class="remove">
            <slot name="remove-icon" />
         </a>
      </span>
      <input
         ref="inputtag"
         :placeholder="placeholder"
         type="text"
         v-on:keydown.delete.stop="removeLastTag"
         v-on:keydown="addNew"
         v-on:blur="handleInputBlur"
         v-on:focus="isInputActive = true"
         class="new-tag"
      />
   </div>
</template>

<script>
export default {
   name: 'ObiTagsInput',
   props: {
      placeholder: {
         type: String,
         default: '',
      },
      addTagOnKeys: {
         type: Array,
         default: function() {
            return [13, 188, 9];
         },
      },
      addTagOnBlur: {
         type: Boolean,
         default: false,
      },
   },
   data() {
      return {
         innerTags: [],
         isInputActive: false,
      };
   },
   methods: {
      focusNewTag() {
         if (!this.$el.querySelector('.new-tag')) {
            return;
         }
         this.$el.querySelector('.new-tag').focus();
      },
      handleInputBlur(e) {
         this.isInputActive = false;
         this.addNew(e);
      },
      async addNew(e) {
         const keyShouldAddTag = e ? this.addTagOnKeys.indexOf(e.keyCode) !== -1 : true;
         const typeIsNotBlur = e && e.type !== 'blur';
         if (!keyShouldAddTag && (typeIsNotBlur || !this.addTagOnBlur)) {
            return;
         }
         if (e.target.value && this.innerTags.indexOf(e.target.value) === -1) {
            this.innerTags.push(e.target.value);
            e.target.value = '';
            this.tagChange();
            e && e.preventDefault();
         }
      },
      remove(index) {
         this.innerTags.splice(index, 1);
         this.tagChange();
      },
      removeLastTag(e) {
         if (e.target.value.length <= 0) {
            this.innerTags.pop();
            this.tagChange();
         }
      },
      tagChange() {
         this.$emit('update:tags', this.innerTags);
         this.$emit('input', this.innerTags);
      },
   },
};
</script>

<style scoped>
.vue-input-tag-wrapper {
   background-color: #fff;
   border: 1px solid #ccc;
   overflow: hidden;
   padding: 12px;
   border-radius: 5px;
   cursor: text;
   text-align: left;
   -webkit-appearance: textfield;
   display: flex;
   flex-wrap: wrap;
}
.vue-input-tag-wrapper .input-tag {
   background-color: #e0e0e0;
   border-radius: 20px;
   border: 1px solid #e0e0e0;
   color: black;
   display: inline-block;
   font-size: 13px;
   font-weight: 400;
   margin-bottom: 4px;
   margin-right: 4px;
   padding: 10px;
}
.vue-input-tag-wrapper .input-tag .remove {
   cursor: pointer;
   font-weight: bold;
   padding: 0px 5px 3px 5px;
   margin-left: 5px;
   border-radius: 50%;
   background-color: rgb(87, 87, 87);
   color: white;
}
.vue-input-tag-wrapper .input-tag .remove:hover {
   text-decoration: none;
}
.vue-input-tag-wrapper .input-tag .remove:empty::before {
   color: rgb(218, 218, 218);
   content: 'x';
}
.vue-input-tag-wrapper .new-tag {
   background: transparent;
   border: 0;
   color: #777;
   font-size: 13px;
   font-weight: 400;
   margin-bottom: 6px;
   margin-top: 1px;
   outline: none;
   padding: 4px;
   padding-left: 0;
   flex-grow: 1;
}
.vue-input-tag-wrapper.read-only {
   cursor: default;
}
</style>
